import React from "react";
import Matchup from "./Matchup";
// reactstrap components
import{
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container, 
  Row
} from "reactstrap";

// core components

function Matchups() {
    // const matchups = [
    //     {"winner": "Double Trouble", "loser": "Bama BlackOut", 
    //         "text": "This one was a high scoring affair, with the two highest scoring teams this week squaring off. It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!"},

    //     {"winner": "! Zo", "loser": "Dandy MEN", 
    //         "text": "Deshaun Watson and Will Fuller set the tone early on Thanksgiving day, and <span className='bold team-name-tag'>Dandy MEN</span> never let up, handing <span className='bold team-name-tag'>! Zo</span> his third straight loss. This loss all but eliminates ! Zo from the playoffs. Dandy can clinch his first ever playoff birth with a win next week against Bama. I'm not gonna lie, I'm rooting for the boy Dandy, even though it's me (Bama) he's playing next week!!!!!"},

    //     {"winner": "The Goonz", "loser": "THE Big D", 
    //         "text": "Dominant. <span className='bold team-name-tag'>The Goonz</span> making it look easy once again to get to the magical <i>SEVENTH</i> win this week putting an exclamation point on an impressive season knocking off <span className='bold team-name-tag'>The Big D</span>. D has really struggled this year following an impressive draft, but has vowed to have studied The League like a playbook and knows what all he did wrong in 2020. Their won't be no easy outs in 2021!!!!!"},


    // ];
  return (
    <>
      <div className="section section-weekly-recap">
        <Container>
            <br />
            <br />
            <br />

            {/* {matchups.map(function(matchup, idx){
                 return (<Matchup key={idx} winner={matchup.winner} loser={matchup.loser} text={matchup.text} />)
             })} */}
        {/* <Matchup winner="Double Trouble" loser="Bama BlackOut" 
        text="This one was a high scoring affair, with the two highest scoring teams this week squaring off. 
        It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping 
        the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the 
        league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!" /> */}
        <Row>
        <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>                            
                
                <span className="bold team-name-tag">Invisible Juice</span> didn’t get the help he needed from the other teams to make the playoffs but he did get
the win against <span className="bold team-name-tag">TOO Easy</span>! Juice had a nice run and would have been a dangerous team to play in the
post season but needed TDs and ZO to lose! Too Easy got his limo ready to pull up to the dance even
after the loss. Juice goes fishing!
                </CardText>          
                </CardBody>
            </Card>   
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">TOO Easy</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">Invisible Juice</CardTitle>          
                </CardBody>
            </Card>            
          </Row>
            <br/>
            <br/>
          <Row>
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">! Zo</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">The Big D</CardTitle>          
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>    
                
                The week of the byes hits <span className="bold team-name-tag">The Big D</span> as he takes the L to <span className="bold team-name-tag">! Zo</span>! !ZO needed the win to keep DT, TDs, Juice
at bay! The Big D has been #1 all year so his hotel is already reserved for after the dance! Both teams
laugh off the game to prepare for the big dance while they buy liquor and condoms!
                </CardText>          
                </CardBody>
            </Card> 
          </Row>
            <br/>
            <br/>
          <Row>            
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                Ouch, disgust and flat out dismay is what <span className="bold team-name-tag">Double Trouble</span> is feeling after not only losing to <span className="bold team-name-tag">Zeke's Supreme Team</span> but 
                also missing the playoffs! DT couldn’t stop the bleeding with those losses and it cost
him dearly! DT was a top team all year and dropped from 3 rd place to 8 th ! Zeke finally got it rolling with
his squad but it was a little too late! Both teams meet up in Cancun!
                </CardText>          
                </CardBody>
            </Card>     
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Double Trouble</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">Zeke's Supreme Team</CardTitle>          
                </CardBody>
            </Card> 
          </Row>
          <br/>
            <br/>
          <Row>       
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">The Goonz</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">Bama BlackOut</CardTitle>          
                </CardBody>
            </Card>        
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>                    
                Plagued by the bye week, <span className="bold team-name-tag">Bama Blackout</span> had a easy road to get into the playoffs while playing against
                <span className="bold team-name-tag"> The Goonz</span>! With no QBs or top Defense this was a “free pussy” game that The Goonz tried to avoid all
year! Luckily The Goonz were in the driver’s seat of their destiny. Sorry for those who need The Goonz to
beat Bama, that’s just how the cookie crumbles!!! Both teams advance to the dance!

                {/* <img
                  alt="..."
                  src={require("assets/img/broke-make-it-rain.gif").default}
                ></img> */}
              
                </CardText>          
                </CardBody>
            </Card>               
          </Row>
          <br/>
          <br/>
          <Row>
          <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>       
                Another bites the dust as <span className="bold team-name-tag">Dandy MEN</span> couldn’t fight at all against the bye and <span className="bold team-name-tag">LET ME SEE YOUR TDs</span>!
Dandy was off to a hot start and even had everyone nervous with those QBs but those string of losses
cost him the playoffs! Meanwhile TDs gets help from a DT and Too Easy loss to catapult him into the
dance! With his date, tux and hotel already set from the previous years, TDs is ready for the 3peat!

                </CardText>          
                </CardBody>
            </Card>                  
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Dandy MEN</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">LET ME SEE YOUR TDs</CardTitle>          
                </CardBody>
            </Card>
          </Row>
          <br/>
          <br/>
          <Row>            
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Sport of Kings</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">King Jaffe Joffer</CardTitle>
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>  
                Not one to shy from disappointment <span className="bold team-name-tag">King Jaffe Joffer</span> been catching L’s like he Yung Berg! The former 3
time champ <span className="bold team-name-tag">Sport of Kings</span> had a bad year but put them paws on KJJ! We all know this was a rough year and I’m sure
both teams are patching up their wounds in Cancun! Next year will be better, next year!

                </CardText>          
                </CardBody>
            </Card>      
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Matchups;
