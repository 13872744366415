import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function WeekSummary() {
  return (
    <>
    {/* <span className="italic bold">dominant</span> */}
    {/* <span className="bold">dominant</span> */}
      <div className="section section-weekly-summary">
        <Container>
          <Row>
            <Col md="12">
            <p className="category">

              {/* <YoutubeEmbed embedId="yiFNrO33bSo"></YoutubeEmbed><br></br> */}
              Gentlemen, <span className="bold green">what a year</span>! It is finally here, <span className="bold blue">The playoffs</span>! For those of you that made it…taste the air,
taste the air…ahhh it taste good don’t it! For everyone that missed it….<span className="bold red">there is always next year</span>!
<span className="bold orange"> Congrats are in order</span>:<br/><br/>

<span className="bold">#1 Seed, Division Champ and Baller of the Year – The Big D</span>: 
This is his second time in league history of being 10-4 but unfortunately the effort didn’t come
with a championship! The Big D is looking to get his first RML Championship but has a tough rough as he
has to go through the winner of Bama/TDs as he sips pina coladas on a bye! We all know the hate runs
deep with the rivalry between Sammy and Rob but you can’t count out the league vet Bama Blackout!!<br/><br/>

<span className="bold">#2 Seed, Division Champ – The Goonz</span>: 
This makes 2 years in a row The Goonz 9-5 have been division champ and received the first
round bye as he has his eye on the prize to go for his 3 rd RML Championship! It’s a new day and age in
RML and to win with this caliber of talent in the league speaks volumes and puts your name on the
Championship belt! Things are never easy as the Goonz wait for the winner of ZO/Too Easy to continue
his quest to become champion!<br/><br/>

<span className="bold">#3 Seed - !ZO</span>: 
When you talk about a turn around season look no further than !ZO 8-6! After a disastrous
season last year at 1-13, ZO wasn’t playing no games this year finishing at 8-6 and making the post
season! But the road is never easy as he has to face off against Too Easy! Put some respect on his name
cause !ZO ain’t playing with yall!<br/><br/>

<span className="bold">#4 Seed - Bama Blackout</span>: 
No stranger to the playoffs, 2 time champ Bama Blackout 8-6 took last year’s unfortunate
season personal! To not make the playoffs is rare for the strategic analysis guru of RML so this year
failure was not an option! But the fantasy road is never easy as Bama has to go against the recent 2 time
champ Let Me See Your TDs! Either way Bama’s mark on the league is a staple!<br/><br/>

<span className="bold">#5 Seed – Let Me See Your TDs</span>: 
Once again Let Me See Your TDs 7-7 proves it’s not how you start but how you finish! With a
tough season the 2 time champ was able to get it out the mud to make the postseason! Ready to

reclaim what is his but going against the OG Bama Blackout won’t be a cake walk! Looking to make his
3 rd championship in a row in RML, TDs has earned the title as champ! Either way, you look at it the road
goes through Let Me See Your TDs!!<br/><br/>

<span className="bold">#6 Seed – Too Easy</span>: 
One thing you can’t deny is Too Easy 7-7 resilience! Regardless of what he has he is able to build
a team ready for the postseason! But as many times as Too Easy has been in the postseason
unfortunately he can’t get the monkey off his back to claim the championship! As a consistent threat
Too Easy looks to move !ZO out his way to move on in the playoffs! Ready for the opportunity to claim
what he is due….the championship!

            {/* Congrats on what's gotta be the most <span className="bold green">entertaining</span>, <span className="bold blue">competitive</span> season in the history of the <span className="bold">League</span>. To the teams that didn't make the playoffs, we thank you for your <span className="bold orange">grit and determination</span>. <br/><br/>
            <div className="text-center"> On the AFC's side, we got two teams:</div>
            <br/>
            <span className="bold">BAMA BLACKOUT:</span> He's been leading the pack all year after starting out 8-0. Can he break through for the first time in 6 years?<br/><br/> */}
            
            </p>
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/devin-white.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeekSummary;
