/*eslint-disable*/
import React from "react";
import Trophy from "./Trophy";

import {
    Container
  } from "reactstrap";

// core components

function Trophies() {
    const trophies = [
        {trophyName: "700-club", first: true, teams: []},
        {trophyName: "600-club", first: true, teams: ["Invisible Juice"]},
        {trophyName: "500-club", first: true, teams: ["TOO Easy", "! Zo", "Zeke's Supreme Team", "LET ME SEE YOUR TDs", "Bama BlackOut", "Sport of Kings" ]},
        {trophyName: "baller-otw", first: true, teams: ["LET ME SEE YOUR TDs"]},
        {trophyName: "donkey-otw", first: true, teams: ["Dandy MEN"]},
        {trophyName: "baller-oty", first: true, teams: ["The Big D"]},
        {trophyName: "donkey-oty", first: true, teams: ["King Jaffe Joffer"]},
        {trophyName: "nfc-champ", first: true, teams: ["The Big D"]},
        {trophyName: "afc-champ", first: true, teams: ["The Goonz"]}
        
    ]

    // let pageHeader = React.createRef();

    // React.useEffect(() => {
    //     if (window.innerWidth > 991) {
    //       const updateScroll = () => {
    //         let windowScrollTop = window.pageYOffset / 3;
    //         pageHeader.current.style.transform =
    //           "translate3d(0," + windowScrollTop + "px,0)";
    //       };
    //       window.addEventListener("scroll", updateScroll);
    //       return function cleanup() {
    //         window.removeEventListener("scroll", updateScroll);
    //       };
    //     }
    //   });
    return (
      <>
      {/* <div className="page-header clear-filter" filter-color="orange">
        <div
          className="page-header-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/trophy-catelog-background.png").default + ")",
              minHeight: "5000px"
          }}
          ref={pageHeader}
        ></div> */}

        <div
          className="section section-trophies"
          style={{
            backgroundImage:
              "url(" + require("assets/img/trophy-catelog-background.png").default + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center"
            // minHeight: "5000px",
          }}
        //   ref={pageHeader}
        >
          <Container>
          {trophies.map(function(trophy, idx){
                 return (<Trophy key={idx} first={trophy.first} trophyName={trophy.trophyName} teams={trophy.teams} />)
             })}
        </Container>
        <br/>
        <br/>
        </div>
      </>
    );
}


export default Trophies;
